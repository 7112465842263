import '../styles/index.scss'
import {createStore, StateMachineProvider, useStateMachine} from "little-state-machine";
import {useRef, useState} from "react";
import AppContext from "../lib/AppContext";
import Script from 'next/script';

createStore({
  user: null,
  cart: null,
  prevScrollPos: 0,
}, {
  persist: 'beforeUnload',
});

function MyApp({ Component, pageProps }) {
  const [appContext, setContext] = useState({ initialized: false });

  return (
    <StateMachineProvider>
      <AppContext.Provider value={[appContext, setContext]}>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"/>
        <Component {...pageProps} />
        <Script>
          {`
            (function (d, t) {
              var BASE_URL = "https://app.chatwoot.com";
              var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
              g.src = BASE_URL + "/packs/js/sdk.js";
              g.defer = true;
              g.async = true;
              s.parentNode.insertBefore(g, s);
              g.onload = function () {
                window.chatwootSDK.run({
                  websiteToken: 'BwQHJFfBqBRjMG7YNnrBMuFZ',
                  baseUrl: BASE_URL
                })
              }
            })(document, "script")`
          }
        </Script>
      </AppContext.Provider>
    </StateMachineProvider>
  );
}

export default MyApp
